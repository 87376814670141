<script>
import moment from 'moment';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import Multiselect from 'vue-multiselect'
import 'vue2-datepicker/locale/pt-br';
import SettingService from '@/services/settings'
import ProductService from '@/services/product'
import { VMoney } from 'v-money'

moment.locale('pt-BR');

export default {
  page: {
    title: "Campanha - Desconto por Produto (Mobwit)",
  },
  components: { Layout, DatePicker, PageHeader, Multiselect },
  directives: { money: VMoney },
  data() {
    return {
      title: "Campanha - Desconto por Produto (Mobwit)",
      loading: false,
      campaigns: [],
      campaign: {},
      products: [],
      modalError: '',
      campaignToRemove: '',
      isLoading: false,

      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      fields: [
        { key: 'productDescription', sortable: false, label: 'Produto' },
        { key: 'startDate', sortable: false, label: 'Data Início', formatter: (value) => {
          return moment(value).format('DD/MM/YYYY');
        } },
        { key: 'endDate', sortable: false, label: 'Data Final', formatter: (value) => {
          return moment(value).format('DD/MM/YYYY');
        } },
        { key: 'minimumValue', sortable: true, label: 'Valor Mínimo', class: 'nowrap-col', formatter: (value) => {
          return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } },
        { key: 'minimumQuantity', sortable: false, label: 'Qtde. Mínima' },
        { key: 'discount', sortable: true, label: '% Desconto', class: 'nowrap-col', formatter: (value) => {
          return `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`
        } },
        { key: 'state', sortable: true, label: 'UF', class: 'nowrap-col', formatter: (value) => {
          return value || 'TODOS'
        } },
        { key: 'actions', label: 'Ações', class: 'nowrap-col' }
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load(showLoading = true, page = 1, term = '') {
      let loader;

      if (showLoading) {
        loader = this.$loading.show();
      }

      try {
        const campaignsResult = await SettingService.getCampaignProductMobwit(term, page);
        this.campaigns = campaignsResult.data.campaigns;

        // eslint-disable-next-line no-console
        console.log(this.campaigns);

        this.count = campaignsResult.data.count;
        this.currentCount = campaignsResult.data.currentCount;
        this.currentPage = campaignsResult.data.currentPage;
        this.totalPages = campaignsResult.data.totalPages;
        this.perPage = campaignsResult.data.perPage;
        this.startIndex = campaignsResult.data.startIndex;
        this.endIndex = campaignsResult.data.endIndex;

        if (showLoading) {
          loader.hide();
        }
      } catch (err) {
        if (showLoading) {
          loader.hide();
        }
        this.$toast.error('Ocorreu um erro ao carregar as configurações');
      }
    },
    async saveCampaign() {
      this.modalError = '';

      if (!this.campaign._id && !this.campaign.product) {
        return this.modalError = 'Informe o produto da campanha';
      }

      const data = {
        ...this.campaign,
        origin: 'mobwit',
        discount: Number(this.campaign.discount.toString().replace('.', '').replace(',', '.')),
        minimumValue: this.campaign.minimumValue ? Number(this.campaign.minimumValue.toString().replace('.', '').replace(',', '.')) : null,
        minimumQuantity: this.campaign.minimumQuantity ? Number(this.campaign.minimumQuantity.toString().replace('.', '').replace(',', '.')) : null,
      }

      if (!this.campaign._id) {
        data.productCode = this.campaign.product.code;
      }

      if (!this.campaign.startDate || !this.campaign.endDate > 0) {
        return this.modalError = 'Informe o período da campanha';
      }

      if (!data.minimumValue && !data.minimumQuantity) {
        return this.modalError = 'Informe o valor ou a quantidade mínima';
      }

      if (!data.discount) {
        return this.modalError = 'Informe o percentual do desconto corretamente';
      }

      this.$bvModal.hide('modal-edit');

      let loader = this.$loading.show();

      try {
        if (this.campaign._id) {
          await SettingService.updateCampaignProduct(data);
        } else {
          await SettingService.createCampaignProduct(data);
        }

        loader.hide();

        this.load(false);

        this.$toast.open('Dados atualizados com sucesso');
      } catch (err) {
        loader.hide();
        
        if (err && err.response.data.code && err.response.data.code === 'E9999') {
          return this.$toast.warning(err.response.data.msg);
        }

        this.$toast.error('Ocorreu um erro inesperado');
      }
    },
    onPageChange() {
      window.scrollTo(0,0);
      this.load(true, this.currentPage);
    },
    search() {
      this.searchEmptyField = false,
      this.load(true, 1, this.searchValue);
    },
    changeSearch() {
      if (this.searchValue === '' && !this.searchEmptyField) {
        this.searchEmptyField = true;
        this.load(true, 1, this.searchValue);
      }
    },
    newCampaign() {
      this.campaign = {
        state: ''
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    editCampaign(item) {
      this.campaign = {
        _id: item._id,
        productDescription: item.productDescription,
        state: item.state || '',
        startDate: new Date(item.startDate),
        endDate: new Date(item.endDate),
        discount: item.discount.toFixed(2).replace('.', ','),
        minimumValue: item.minimumValue ? item.minimumValue.toFixed(2).replace('.', ',') : '',
        minimumQuantity: item.minimumQuantity ? item.minimumQuantity.toString().replace('.', ',') : '',
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    removeCampaign() {
      this.$bvModal.hide('modal-remove');

      SettingService.removeCampaignProduct(this.campaignToRemove).then(() => {
        this.load(false);
        this.$toast.open('Campanha removida com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao remover a campanha');
      })
    },
    findProduct(term) {
      this.isLoading = true;

      ProductService.getUniqueProducts(term).then(res => {
        this.isLoading = false;

        this.products = res.data;
      }).catch(() => {
        this.isLoading = false;
      })
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2 align-items-center">
              <div class="col-sm-8 col-lg-6">
                <div class="mr-2 mb-2">
                  <div class="position-relative search-input">
                    <form @submit.prevent="search" class="align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="searchValue"
                        v-on:keyup="changeSearch"
                        placeholder="Pesquisar por nome ou código do produto"
                      />

                      <button type="submit" class="btn btn-primary" v-if="!loading">
                        Pesquisar
                      </button>

                      <a href="javascript:void(0);" class="text-primary" v-if="loading">
                        <b-spinner class="ml-2" variant="primary" role="status"></b-spinner>
                      </a>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-sm-4 col-lg-6">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="newCampaign()">
                    <i class="mdi mdi-plus mr-1"></i> Nova Campanha  
                  </button>
                </div>
              </div>
            </div>  

            <div>
             
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="campaigns"
                :fields="fields"
                responsive="sm"
                head-variant="light"
              >
                <template v-slot:cell(actions)="row">
                 

                  <a href="javascript:void(0);" class="action-icon text-success mr-3" @click="editCampaign(row.item)">
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a href="javascript:void(0);" class="action-icon text-danger" v-b-modal.modal-remove @click="campaignToRemove = row.item._id" >
                    <i class="mdi mdi-close-circle-outline font-size-18"></i>
                  </a>
                </template>
              </b-table>

              <div class="row justify-content-md-between align-items-md-center mt-4">
                <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>
                <!-- end col-->
                <div class="col-xl-5">
                  <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                    <b-pagination
                      v-model="currentPage" 
                      :total-rows="count"
                      :per-page="perPage"
                      @input="onPageChange"
                    ></b-pagination>
                  </div>
                </div>
                <!-- end col-->
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <b-modal id="modal-edit" :title="campaign._id ? 'Alterando Campanha' : 'Nova Campanha'" centered>
      <b-alert v-if="modalError" v-model="modalError" variant="danger" dismissible>{{modalError}}</b-alert>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="control-label">Produto</label>

            <input
              id="productDescription"
              name="productDescription"
              type="text"
              class="form-control"
              :disabled="true"
              v-if="campaign._id"
              :value="campaign.productDescription"
            />

            <multiselect
              v-model="campaign.product"
              :options="products"
              :close-on-select="true"
              :clear-on-select="true"
              :loading="isLoading"
              @search-change="findProduct"
              placeholder="Selecione o produto por nome ou código"
              label="fullDescription"
              track-by="code"
              selectLabel="Enter para selecionar"
              selectedLabel="Selecionado"
              deselectLabel="Enter para remover"
              v-if="!campaign._id"
            ></multiselect>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <div class="form-group mb-3">
            <label>Período de</label>
            <date-picker v-model="campaign.startDate" :first-day-of-week="1" format="DD/MM/YYYY" lang="pt-br"></date-picker>
          </div>
        </div>

        <div class="col-4">
          <div class="form-group mb-3">
            <label>até</label>
            <date-picker v-model="campaign.endDate" :first-day-of-week="1" format="DD/MM/YYYY" lang="pt-br"></date-picker>
          </div>
        </div>

        <div class="col-4">
          <div class="form-group mb-3">
            <label>Estado</label>
            <select
              v-model="campaign.state"
              class="form-control"
              name="activityType"
              :disabled="campaign._id"
            >
              <option value="">TODOS</option>
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label for="productStock">Valor Mínimo</label>

            <input
              id="minimumValue"
              name="minimumValue"
              type="tel"
              class="form-control"
              v-money="money"
              v-model="campaign.minimumValue"
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label for="productStock">Qtde. Mínima</label>

            <input
              id="minimumQuantity"
              name="minimumQuantity"
              type="tel"
              class="form-control"
              v-model="campaign.minimumQuantity"
            />
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group">
            <label for="productStock">% Desconto</label>

            <input
              id="discount"
              name="discount"
              type="tel"
              class="form-control"
              v-money="money"
              v-model="campaign.discount"
            />
          </div>
        </div>

        <!-- <div class="col-sm-3">
          <div class="form-group">
            <label for="productStock">% Notificação</label>

            <input
              id="limitToNotify"
              name="limitToNotify"
              type="tel"
              class="form-control"
              v-money="money"
              v-model="campaign.limitToNotify"
            />
          </div>
        </div> -->
      </div>
      
      <template v-slot:modal-footer>
        <b-button variant="link" @click="$bvModal.hide('modal-edit')">Cancelar</b-button>
        <b-button variant="primary" @click="saveCampaign()">
          Gravar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-remove" title="Excluir Campanha" title-class="font-18" centered>
      <p>Confirma a exclusão da campanha?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeCampaign">Excluir</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .search-input form {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: 10px;
    }
  }

  .form-control:disabled {
    background-color: #e6e6e6;
  }
</style>